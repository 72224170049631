<template>
  <div>
    <div class="breadcrumb">
       <div style='text-align:right'>
          <el-button @click='$router.go(-1)'>返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon">
        </div>
        身份认证
      </h2>
      <!--搜索条件区域-->
      <div class="Wrapper" style="float: left;width: 100%;">
        <el-form label-position="left" label-width="160px" :model="formInline" style="width: 100%; float:left;" :ref="'form'">
          <el-form-item label="会员手机号：" label-width="100px"  style="float: left;width: 33%;" prop='mobile'>
              <p>{{formInline.mobile}}</p>
          </el-form-item>
          <el-form-item label="提交日期：" label-width="100px"  style="float: left;width: 33%;" prop="applyTime">
            <span>{{formInline.applyTime}}</span>
          </el-form-item>
          <el-form-item label="认证状态：" label-width="100px"  style="float: left;width: 33%;" v-if="this.labelnumber == 0"  prop="authStatus">
            <span v-if="formInline.authStatus == 0">待审核</span>
            <span v-if="formInline.authStatus == 1">已审核</span>
            <span v-if="formInline.authStatus == 2">已作废</span>
          </el-form-item>
          <el-form-item v-if="this.$route.query.authStatus != 0" label="操作人：" label-width="100px"  style="float: left;width: 33%;" prop='operation'>
              <span>{{formInline.operation}}</span>
          </el-form-item>
          <el-form-item v-if="this.$route.query.authStatus != 0" label="操作日期：" label-width="100px"  style="float: left;width: 50%;" prop="label">
            <span>{{formInline.authTime}}</span>
          </el-form-item>
          <el-form-item v-if="this.$route.query.authStatus == 2" label="拒审理由：" style="width: 80%" label-width="100px"  prop="refuseDesc" >
              <el-input type="textarea" rows="4" placeholder="请输入拒审理由" v-model.trim="formInline.refuseDesc"></el-input>
          </el-form-item>
          <el-form-item label="会员提交身份认证信息" style="width: 100%;float: left;" v-if="this.labelnumber == 0"  prop="parkCode">
          </el-form-item>
          <div style="margin: 0 auto;width:60%;">
            <el-form-item label="姓名：" label-width="100px"  style="float: left;width: 50%;" prop="label">
              <span>{{formInline.realName}}</span>
            </el-form-item>
            <el-form-item label="身份证号：" label-width="100px"  style="float: left;width: 50%;" v-if="this.labelnumber == 0"  prop="parkCode">
              <span>{{formInline.idcard}}</span>
            </el-form-item>
            <el-row :gutter="20" style="margin-top: 20px;">
              <el-col :span="12">
                <div class="img"><img :src='formInline.frontIdcardImage' @click="showBigPic(formInline.frontIdcardImage)" alt="" style="width: 100%; height:100%"></div>
              </el-col>
              <el-col :span="12">
                <div class="img"><img :src='formInline.backIdcardImage' @click="showBigPic(formInline.backIdcardImage)" alt="" style="width: 100%; height: 100%"></div>
              </el-col>
            </el-row>
          </div>
          <div style="margin: 0 auto;width: 820px;margin-top: 50px;" v-if="this.$route.query.authStatus == 0">
            <el-button type="primary" @click="through">通过</el-button>
            <el-button type="" @click="Refused">拒绝</el-button>
            <el-button type="" @click="$router.go(-1)">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 弹窗 -->
    <picDetail :detailFlag="dialogVisible"  @close="dialogVisible=false" @closeb="$router.go(-1)"></picDetail>
    <transition name="fade">
      <div class="mask" v-show="bigPic" :style="{'width': '500px','height': 'auto','margin': '0 auto','z-index': '10000', 'background-image': picUrl}" @click="bigPic=false">
      </div>
    </transition>
  </div>
</template>
<script>
  import {
    request
   // dateFormat
  } from '../../common/js/public.js'
  import picDetail from './picDetail'
  export default {
    name: 'hello',
    data () {
      return {
        portraittwo: "./car-pic.png",
        dialogVisible: false,
        picUrl: '',
        bigPic: false,
        labelnumber: '0',
        openTime: '',
        fileChang: false,
        parkState: [],
        parkScope: [],
        key1: '',
        city: '',
        parkName: '',
        dis: false,
        lnglat: '',
         pickerOptions1: {
          disabledDate (time) {
            return time.getTime() < Date.now();
          }
         },
         uploadHeader: {
           'Session-Id': sessionStorage.token
         },
         uploadData: {
           parkId: ''
         },
        workStateAll: [],
        treeData: [],
        parkType: [],
        parkList: '',
        parkParent: [],
        butreeData: [],
        areaName: '',
        businessName: '',
        businessType: [],
        slaveRelation: [],
        cooperationModel: [],
        commercialStatus: [],
        parkEquipmentType: [],
        imageUrl: '',
        payMode: [],
        isEdit: false,
        loading: false,
        effective: '1',
        startTime: '',
        defaultProps: {
            label: 'label',
            children: 'children'
        },
       formInline: {
         refuseDesc: ''
        }
      }
    },
    methods: {
      Refused() {
        this.dialogVisible = true;
        // this.$router.go(-1)
      },
      showBigPic (url) {
        if (!url) {
          return false;
        }
        this.picUrl = `url("${url}")`;
        this.bigPic = true;  	
      },
       getParkImg () {
        let opt = {
          method: 'get',
          url: '/acb/2.0/memberIdcardAuth/getById',
          data: {
            idcardauthId: this.$route.query.idcardauthId
          },
          success: (res) => {
            this.formInline = res.value;
          }
        }
        request(opt)
      },
      through () {
        this.$confirm("请您确认审核资料是否无误！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          let opt = {
            method: 'get',
            url: '/acb/2.0/memberIdcardAuth/auth',
            data: {
              idcardauthId: this.$route.query.idcardauthId,
              authStatus: '1',
              // 拒绝理由
              refuseDesc: this.formInline.refuseDesc
            },
            success: (res) => {
              this.$router.go(-1)
            }
          }
          request(opt)
        }).catch(err => {})
      }
    },
    components: {
      picDetail
    },
    created () {
      this.getParkImg()
    },
    mounted () {
    },
    computed: {
    }
  }
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
  .Wrapper
    overflow hidden
  .breadcrumb
    height: 50px;
  .content
    background: #FFFFFF;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 20px;
  .uploadPic
    float: left;
    margin-left: 150px;
    width: 295px;
    height: 178px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    img{
      width: 100%;
      height: 100%;
    }
.mask
	background: rgba(0,0,0,0) no-repeat center/contain;
	// background: rgba(49,53,65,0.60) no-repeat center/contain;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
</style>
<style>
  .dialog_button {
    width: 100%;
    height: 40px;
  }
  .img {
    width: 100%;
    height: 200px;
    background: url("car-pic.png") center no-repeat;
  }
</style>

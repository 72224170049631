<template>
  <div>
    <transition name="fade">
      <div class="mask" v-show="detailFlag">
        <transition name="fade">
          <div class="detail" v-show="detailFlag">
            <div class="header">
              {{ "拒绝详情" }}
              <i class="el-icon-close" @click="$emit('close')"></i>
            </div>
            <div class="content">
              <div class="picWrapper">
                <div class="parkDeatail">
                  <div class="parkInfo">
                    <!-- <h2 class="parkingInfo">
                  <a class="parkingInfo-on"></a>
                  停车场信息
                </h2> -->
                    <div class="parkinfoDeatail">
                      <div style="width: 140px">请输入拒审理由：</div>
                      <div>
                        <el-input
                          v-model.trim="formInline.text"
                          rows="4"
                          placeholder="请输入内容"
                          type="textarea"
                          maxlength="300"
                          :show-word-limit="true"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <el-row>
                  <div style="margin: 0 auto; width: 200px; margin-top: 50px">
                    <el-button type="primary" @click="Refused">提交</el-button>
                    <el-button type="" @click="$emit('close')">取消</el-button>
                  </div>
                </el-row>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
import {
  request,
  // dateFormat
} from "../../common/js/public.js";
export default {
  name: "picDetail",
  data() {
    return {
      showParkRecord: false,
      page: 1,
      pageSize: 15,
      formInline: {
        text: "",
      },
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    Refused() {
      if (!this.formInline.text) {
        this.$alert("请输入拒绝理由", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.dialogVisible = true;
      let opt = {
        method: "get",
        url: "/acb/2.0/memberIdcardAuth/auth",
        data: {
          idcardauthId: this.$route.query.idcardauthId,
          authStatus: 2,
          // 拒绝理由
          refuseDesc: this.formInline.text,
        },
        success: (res) => {
          this.$emit("close");
          this.$emit("closeb");
          this.$emit(this.formInline.text);
        },
      };
      request(opt);
    },
  },
  props: {
    detailFlag: {
      default: false,
    },
  },
  components: {},
  created() {
    this.formInline.text = "";
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper
  .el-pagination
    text-align: right;
    padding-top: 20px;
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}
.detail
  width: 61.4%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128,145,165,0.30);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;
  .header
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;
    .el-icon-close
      float: right;
      margin-top: 14px;
  .content
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
    .picWrapper
      .picBox
        float: left;
        width: 33.33333%;
        padding: 0 20px 15px 0;
        box-sizing: border-box;
      .title
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #8091A5;
        letter-spacing: 0;
        line-height: 16px;
      .pic
        width: 100%;
        height: 150px;
        border: 1px solid #8091A5;
        margin-top: 10px;
        overflow: hidden;
    .infoWrapper
      border-top: 1px solid #D3DCE6;
      padding-top: 20px;
      .title
        height: 42px;
        line-height: 42px;
        color: #8391a5;
        font-size: 14px;
        width: 100%;
        border-bottom: 1px solid #d1dbe5;
        margin: 0 0 15px;
      .info
        font-size: 12px;
        color: #313541;
        letter-spacing: 0;
        line-height: 32px;
        overflow: hidden;
        .infoItem
          width: 33.3333%;
          box-sizing: border-box;
          float: left;
          .label
            width: 100px;
            text-align: right;
            display: inline-block;
    .footer
      clear: both;
      text-align: center;
      margin-top: 50px;
.mask
  background: rgba(49,53,65,0.60) no-repeat center/contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
</style>

<style scoped>
.parkTitle {
  border-bottom: 1px solid #e1e1e1;
}
.parkDeatail {
  padding-left: 15px;
}
.parkInfo {
  /* border-bottom: 2px solid #e0e6ed;
   	padding-bottom: 12px; */
}
.parkingInfo {
  font-family: "PingFang";
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  padding-left: 14px;
  font-weight: 500;
}
.parkingInfo-on {
  position: absolute;
  left: 0;
  top: 31%;
  width: 4px;
  height: 16px;
  background: #0099fa;
}
.parkinfoDeatail {
  display: flex;
  color: #566475;
  margin-top: 30px;
}
.parkinfoDeatail div:first-child {
  width: 50%;
  height: 30px;
  line-height: 30px;
}
.parkinfoDeatail div:first-child > span:first-child {
  width: 100px;
  display: inline-block;
}
.parkinfoDeatail div:last-child {
  width: 100%;
  height: auto;
}
.parkinfoDeatail div:last-child > span:first-child {
  width: 100px;
  display: inline-block;
}
.parkTitle span {
  text-align: center;
  display: inline-block;
  width: 100px;
  line-height: 30px;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
}
.picTitle {
  line-height: 30px;
}
.detail1 {
  line-height: 25px;
}
.pic-title {
  padding-left: 15px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 900;
}
</style>
